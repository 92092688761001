* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-mono: "IBM Plex Mono", monospace;
  --font-default: "IBM Plex Sans", sans-serif;

  --color-burnt-sienna: #dd6e42ff;
  --color-dutch-white: rgb(232, 218, 178);
  --color-dutch-white-transparent: rgba(232, 218, 178, 0.5);
  --color-paynes-gray: #4f6d7aff;
  --color-columbia-blue: #c0d6dfff;
  --color-platinum: #eaeaeaff;
  --color-success: rgb(16, 171, 5);
  --color-success-transparent: rgba(16, 171, 5, 0.5);
  --color-progress: rgb(223, 172, 4);
  --color-progress-transparent: rgba(223, 172, 4, 0.5);
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: var(--font-default);
  font-size: 20px;
}

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border-bottom: 2px solid #dddddd;
}

#header-logo-img {
  height: 40px;
}

.btn {
  border: 0;
  font: inherit;
  font-size: 0.7rem;
  padding: 8px 30px;
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;

  &.primary {
    background-color: var(--color-burnt-sienna);
    color: #ffffff
  }
}

.small-btn {
  text-decoration: none;
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-platinum);
  border: 0;
  border-radius: 4px;
  padding: 5px;
  // color: var(--color-burnt-sienna);
  &[disabled] {
    color: white;
  }
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.circle-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--color-burnt-sienna);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#header-name {
  font-size: 0.8rem;
  margin-right: 20px;
}

#content-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

#puzzle-list-page-selection, #puzzle-details-page-heading {
  margin: 20px 0;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  button, a {
    text-decoration: none;
    cursor: pointer;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-platinum);
    border: 0;
    border-radius: 4px;
    padding: 5px;
    color: var(--color-burnt-sienna);
    &[disabled] {
      color: white;
    }
  }
}

#puzzle-details-page-heading {
  justify-content: space-between;
}

#puzzles-list-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.puzzle-list-item {
  width: calc(100%/6);
  padding: 20px;
}

.puzzle-item-inner {
  border: 2px solid var(--color-dutch-white);
  border-radius: 8px;
  text-align: center;
  padding: 20px 0 0;
  cursor: pointer;
  display: block;
  color: inherit;
  text-decoration: none;
  &:hover {
    background-color: var(--color-dutch-white-transparent);
  }
  &.completed {
    &:hover {
      background-color: var(--color-success-transparent);
    }
  }
  &.progress {
    &:hover {
      background-color: var(--color-progress-transparent);
    }
  }
  p {
    padding: 5px 0;
    border-top: 2px solid var(--color-dutch-white);
    margin-top: 20px;
  }
  &.completed {
    border: 2px solid var(--color-success);
    p {
      color: var(--color-success);
      border-top: 2px solid var(--color-success);
    }
    .material-symbols-outlined {
      color: var(--color-success);
    }
  }
  &.progress {
    border: 2px solid var(--color-progress);
    p {
      color: var(--color-progress);
      border-top: 2px solid var(--color-progress);
    }
    .material-symbols-outlined {
      color: var(--color-progress);
    }
  }
}

.puzzle-line {
  display: flex;
  padding: 30px 0;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
  &.right {
    justify-content: flex-end;
  }
}

.puzzle-word {
  padding: 0 20px;
  display: flex;
}

.letter {
  text-transform: uppercase;
  padding: 20px 5px;
  text-align: center;
  input {
    caret-color: transparent;
    cursor: pointer;
    width: 30px;
    padding: 10px 0;
    border: 0;
    background-color: #f0f0f0;
    font: inherit;
    text-align: center;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 730px) {
  #content-wrapper {
    padding: 20px 10px;
  }

  .puzzle-list-item {
    width: calc(100%/3);
    padding: 20px;
  }

  .letter {
    font-size: 0.7rem;
    input {
      width: 24px;
    }
  }

  .puzzle-word {
    padding: 0 10px;
  }
}

#puzzle-details-options {
  display: flex;
  align-items: center;
}

.flex-gap {
  flex-grow: 1;
  width: 1px;
}

#puzzle-leaderboard {
  width: 100%;
  max-width: 600px;
  padding: 100px 0;
  margin: 0 auto;
  margin-bottom: 100px;
  h4 {
    text-align: center;
    margin-bottom: 20px;;
  }
}

.leaderboard-row {
  display: flex;
  padding: 10px 0;
  border-bottom: 2px solid #fafafa;
  font-size: 0.9rem;
  &.bold {
    font-weight: bold;;
  }
}

.lb-name {
  flex-grow: 1;
}

#leaderboard-empty {
  text-align: center;
}